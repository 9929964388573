<template>
    <div class="text-center">
        <v-menu bottom offset-y min-width="200px">
            <template v-slot:activator="{ on }">
                <v-btn v-bind="$attrs" v-on="on">
                    <v-avatar color="primary" size="42">
                        <v-img
                            src="https://randomuser.me/api/portraits/women/44.jpg"
                        />
                    </v-avatar>
                </v-btn>
            </template>

            <v-list dense>
                <v-list-item
                    dense
                    v-for="(link, index) in links"
                    :key="index"
                    link
                    :to="{ name: link.route }"
                >
                    <!-- Link icon -->
                    <span class="mr-1">
                        <v-icon dense left>
                            {{ link.icon }}
                        </v-icon>
                    </span>

                    <!-- Link label -->
                    <span class="mt-1">
                        {{ link.name }}
                    </span>
                </v-list-item>
            </v-list>

            <v-divider class="mx-5" />

            <v-list dense>
                <v-list-item dense link :to="{ name: 'Logout' }">
                    <!-- Link icon -->
                    <span class="mr-1">
                        <v-icon dense left> mdi-logout </v-icon>
                    </span>

                    <!-- Link label -->
                    <span class="mt-1"> Logout </span>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
export default {
    name: "customMenu",
    props: {
        name: { default: "" },
        links: {
            default: [
                {
                    icon: "mdi-cog-outline",
                    route: "Settings",
                    name: "Settings",
                },
                {
                    icon: "mdi-account-circle",
                    route: "Profile",
                    name: "Profile",
                },
                {
                    icon: "mdi-email",
                    route: "Messages",
                    name: "Mesages",
                },
            ],
        },
    },
    // Lifecycle hooks.
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
};
</script>

